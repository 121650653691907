import Vue from "vue";
import Vuex from "vuex";
let token = localStorage.getItem("token");
let enmu = localStorage.getItem("enmu");
let examId = localStorage.getItem("examId")
let patientId = localStorage.getItem("patientId")

// let enmu = localStorage.getItem('enmu')
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token,
    enmu,
    examId,
    patientId,
    setlist: {},
  },
  getters: {
    enmu: (state) => {
      return state.enmu;
    },
    token: (state) => {
      return state.token;
    },
    examId: (state) => {
      return state.examId;
    },
    patientId: (state) => {
      return state.patientId;
    },
    // setlist:(state)=>{
    //   return state.setlist
    // },
  },
  mutations: {
    set_enmu(state, enmu) {
      state.enmu = enmu;
      localStorage.enmu = enmu;
    },
    set_token(state, token) {
      state.token = token;
      localStorage.token = token;
    },
    set_examId(state, examId) {
      state.examId = examId;
      localStorage.examId = examId;
    },
    set_patientId(state, patientId) {
      state.patientId = patientId;
      localStorage.patientId = patientId;
    },
    set_historylist(state, data) {
      state.setlist = data;
    },
  },
  actions: {},
  modules: {},
});
